import {Box, Button, Divider, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import FlexButton from "./Buttons/FlexButton.tsx"
import FlexTitle from "./Titles/flexTitle.js"
import { useEffect, useRef, useState } from "react"
import { useIsSP } from "./isSP.js"

const Contact = () => {


    const stackRef = useRef(null);
    const [mainFontSize, setMainFontSize] = useState(20);
    const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
    const isSP = useIsSP()

    useEffect(() => {
        const handleResize = () => {
            if (stackRef.current) {
                const width = stackRef.current.offsetWidth;
                setMainFontSize(Math.min(Math.max(width/ (isSP ? 18: 25), 16), 24))
            }
            setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
        };

        handleResize(); // 初回実行
        window.addEventListener('resize', handleResize); // ウィンドウサイズ変更時にも実行

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, [stackRef.current]);

    return(
        <Stack ref={stackRef} mt={10} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <FlexTitle mainTitle={"Contact"} subTitle={"Free Consultation"} />
            <Stack direction={isSP ? "column" : "row"} justifyContent={"space-between"} width={"100%"} spacing={2} >
                <Stack width={isSP ? "100%" : "50%"} alignItems={"flex-start"} spacing={1}>
                    <Typography sx={{fontSize:mainFontSize, color:"black", fontWeight:"bold"}}>Ready to explore the Japanese market?</Typography>
                    <Typography sx={{fontSize:Math.max(mainFontSize-4, 14), color:"black",}}>We offer a comprehensive range of services tailored to meet your unique needs. Schedule a complimentary consultation today to discuss how we can support your goals.</Typography>
                </Stack>
                <img src={"/images/contact.png"} style={{width:isSP ? "100%" : "50%", height: isSP ? undefined : 300*ratio+"px", objectFit:"cover", borderRadius:"10px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />   
            </Stack>

            <Stack pt={4} width={"100%"} alignItems={"flex-start"}>
                <FlexButton borderRadius="3px" fontWeight={"bold"}  text="Contact" width={isSP ? "100%" : "30%"} height={isSP ? "60px" : "60px"} fontSize={isSP ? 18 : 15} backgroundColor="#363636" onClick={()=>{window.location.href = "/contact"}}/>
            </Stack>
            
        </Stack>
    )
}

export default Contact