import Header from '../components/header.js';
import MainImage from '../components/main_image.js';
import {Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography, colors} from "@mui/material"
import React, { useState } from 'react';
import { useIsSP } from '../components/isSP.js';
import LimitedMultilineOutlinedBordered from '../components/Textfields/limited_multiline_outlined_bordered.js';
import FlexButton from '../components/Buttons/FlexButton.tsx';
import axios from "axios"

function Home() {

  const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
  const isSP = useIsSP()

  const [first_name, setFirstName] = useState("")
  const [family_name, setFamilyName] = useState("")
  const [company_name, setCompanyName] = useState("")
  const [loading, setLoading] = useState(false)
  const [sendStatus, setSendStatus] = useState(0)
  const [mail, setMail] = useState("")
  const [message, setMessage] = useState("")
  

   React.useEffect(() => {
    const handleResize = () => {
      setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(mail)

  const textfield = (text, setText) => {
    console.log(setText)
    return <LimitedMultilineOutlinedBordered borderRadius={Math.min(3*ratio, 3)} padding={"8px 10px"} fontSize={Math.max(12 * ratio, isSP ? 14: 10)} border={"0.5px solid #363636"}  backgroundColor={"white"} hovered_backgroundColor={"white"} text={text} setValue={setText} />
  }

  return (
    <Stack pb={40} width={"100%"} sx={{backgroundColor:"#F9F9F9"}}> 
      <Header/>
      <MainImage title={"Contact"} subTitle={"- Free Consultant -"} displayContact={false} />

      <Stack pb={0} alignItems={"center"} width={"100%"} >
        <Stack spacing={3} p={isSP ? 2 : 4} pt={isSP ? 4 : undefined}  width={isSP ? "80%" : 100 - 30 * ratio+"%"} sx={{zIndex:10, borderRadius:"3px",  backgroundColor:"white"}}>
            <Typography textAlign={"center"}  sx={{ fontSize:Math.max(12 * ratio, isSP ? 14: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"If you have any question, feel free to contact us.\nYou can ask us about the Japan Market Entry in this free consultation."}</Typography> 
            <Stack pt={4} width={"100%"} spacing={3} direction={"row"}>
              <Stack width={"100%"} justifyContent={"flex-start"}>
                <Typography textAlign={isSP ? "flex-start" : undefined }  sx={{ color:"grey", fontSize:Math.max(12 * ratio, isSP ? 14: 10), zIndex:1, whiteSpace:"pre-wrap"}}>{"First Name"}</Typography>
                {textfield(first_name, setFirstName)}
              </Stack>
              <Stack width={"100%"} justifyContent={"flex-start"}>
                <Typography textAlign={isSP ? "flex-start" : undefined }  sx={{ color:"grey", fontSize:Math.max(12 * ratio, isSP ? 14: 10), zIndex:1, whiteSpace:"pre-wrap"}}>{"Family Name"}</Typography>
                {textfield(family_name, setFamilyName)}
              </Stack>
            </Stack>
            <Stack justifyContent={"flex-start"}>
              <Typography textAlign={isSP ? "flex-start" : undefined }  sx={{ color:"grey", fontSize:Math.max(12 * ratio, isSP ? 14: 10), zIndex:1, whiteSpace:"pre-wrap"}}>{"Company Name"}</Typography>
              {textfield(company_name, setCompanyName)}
            </Stack>
            <Stack justifyContent={"flex-start"}>
              <Typography textAlign={isSP ? "flex-start" : undefined }  sx={{ color:"grey", fontSize:Math.max(12 * ratio, isSP ? 14: 10), zIndex:1, whiteSpace:"pre-wrap"}}>{"Mail Address"}</Typography>
              {textfield(mail, setMail)}
            </Stack>
            <Stack justifyContent={"flex-start"}>
              <Typography textAlign={isSP ? "flex-start" : undefined }  sx={{ color:"grey", fontSize:Math.max(12 * ratio, isSP ? 14: 10), zIndex:1, whiteSpace:"pre-wrap"}}>{"Message"}</Typography>
              {textfield(message, setMessage)}
            </Stack>

            <Stack pt={4} width={"100%"} alignItems={"center"}>
                <FlexButton borderRadius={"3px"} fontWeight={"bold"}  text="Submit" width={isSP ? "40%" : "20%"} height={isSP ? "40px" : "40px"} fontSize={isSP ? 18 : 15} backgroundColor="#363636" onClick={()=>{
                  setLoading(true)
                  setSendStatus(1)
                  fetch("https://api.market-entry-jp.com", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: `first_name=${encodeURIComponent(first_name)}&family_name=${encodeURIComponent(family_name)}&company_name=${encodeURIComponent(company_name)}&email=${encodeURIComponent(mail)}&message=${encodeURIComponent(message)}`
                  })
                    .then(response => {
                      setSendStatus(2)
                      setLoading(false)
                    })
                    .catch(error => {
                      setSendStatus(3)
                      setLoading(false)
                      console.error("GETリクエストが失敗しました", error);
                    });
                }}/>
            </Stack>
        </Stack>
      </Stack>

      {/* ロード状態 */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
        onClick={()=>{}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      {/* メール送信後ステータス */}
      <Dialog
        open={loading === false && sendStatus !== 0}
        onClose={()=>{setSendStatus(0)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {sendStatus !== 0 && (sendStatus === 2 ? "Sent Successfully" : "Failed")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {sendStatus !== 0 && (sendStatus === 2 ? "Your message has successfully sent." : "Your message has failed to send. Please check your email address.")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setSendStatus(0)}} color="primary" autoFocus>
          {sendStatus !== 0 && "閉じる"}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Home;