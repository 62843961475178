import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Button, CircularProgress } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

interface Props {
    onClick: () => void;
    text?: string;
    width?: string;
    height?: string;
    color?: string;
    fontSize?: number;
    padding?: string;
    border?: string;
    borderRadius?: string;
    boxShadow?:string;
    backgroundColor?:string;
    borderTop?: string;
    selectedBorderTop?: string;
    isMobile?: boolean;
    disabled?: boolean;
    loading?: boolean;
    bold?:boolean;
    loadingSize?: number;
    selected?: boolean;
    selectedBackGroundColor?: string;
    selectedColor?: string;
    selectedBorder?: string;
}


// 投稿ボタン
const FlexButton: React.FC<Props> = ({onClick, text, width, height, padding, border, borderTop, selectedBorderTop, color, fontSize, borderRadius, boxShadow, backgroundColor, loading, isMobile, disabled, bold, loadingSize, selected, selectedBackGroundColor, selectedColor, selectedBorder}) => {
    
    return (
        <>
            {/* 「もっと見る」ボタン */}
            <Button variant='contained' disabled={disabled == true} size='large' sx={{
                padding: padding ?? "none", 
                backgroundColor: selected ?( selectedBackGroundColor ?? "#383E86") : (backgroundColor ?? "#383E86"),
                width: width,
                height: height ?? "8em",
                border: selected ? (selectedBorder ?? undefined) :( border ?? undefined),
                borderTop: selected ? (selectedBorderTop ?? undefined) : (borderTop ?? undefined),
                borderRadius: borderRadius ?? '0px',
                boxShadow: boxShadow ?? '3px 4px 4px rgba(0, 0, 0, 0.25)',
                '&:active': {
                    backgroundColor: selected ?( selectedBackGroundColor ?? "#383E86") : (backgroundColor ?? "#383E86"), // 押したときの色を変更
                    opacity: 0.9,
                    boxShadow: boxShadow ?? '3px 4px 4px rgba(0, 0, 0, 0.25)',
                },
                '&:hover': {
                    backgroundColor: selected ?( selectedBackGroundColor ?? "#383E86") : (backgroundColor ?? "#383E86"), // hover時のを変更
                    opacity: 0.9,
                    boxShadow: boxShadow ?? '3px 4px 4px rgba(0, 0, 0, 0.25)',
                }
                }}
                onClick={() => {onClick()}}>
                {loading &&
                    <CircularProgress size={loadingSize} sx={{marginRight: "10px", color: selected ? ( selectedColor ?? "white") : (color ?? "white") }} color="inherit" />
                }
                <Typography sx={{ fontSize: fontSize ?? (isMobile ? "18px" : '24px'),fontWeight: bold ? "bold" : undefined, color: selected ? ( selectedColor ?? "white") : (color ?? "white"),}}>
                    {text ?? ""}
                </Typography>
            </Button>
        </>
    );
};

export default FlexButton;
