import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import Header from '../components/header';
import MainImage from '../components/main_image';
import { useIsSP } from '../components/isSP';
import { useIsMobile } from '../components/isMobile';

function Service() {
  const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)));
  const isSP = useIsSP();
  const isMobile = useIsMobile();
  const [expandedFeatures, setExpandedFeatures] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)));
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFeature = (serviceIndex, featureIndex) => {
    setExpandedFeatures(prev => ({
      ...prev,
      [`${serviceIndex}-${featureIndex}`]: !prev[`${serviceIndex}-${featureIndex}`]
    }));
  };

  const services = [
    {
      title: 'Consulting & Real-World Test Marketing in Japan',
      description: 'We offer a unique service that combines market entry consulting with practical test marketing using crowdfunding campaigns.',
      imageSrc: '/クラファン.png',
      alt: 'Consulting and Crowdfunding',
      features: [
        {
          title: 'Market Analysis',
          description: 'We conduct thorough analysis of the Japanese market to ensure your product\'s success. Our team analyzes trends, consumer behavior, and competitor strategies to provide you with actionable insights.'
        },
        {
          title: 'Product Adjustment',
          description: 'We offer advice on adapting your product to Japanese consumer preferences. Based on our market analysis, we suggest modifications to your product to better appeal to Japanese consumers.'
        },
        {
          title: 'Test Marketing',
          description: 'We use crowdfunding campaigns as a practical method for test marketing. This approach allows us to gauge real market interest and collect valuable feedback before a full-scale launch.'
        }
      ]
    },
    {
      title: 'Creating Japanese Landing Pages',
      description: 'We create specialized Japanese landing pages for each product, effectively approaching the Japanese customer base.',
      imageSrc: '/LP集.png',
      alt: 'Japanese Landing Page',
      features: [
        {
          title: 'Localization',
          description: 'We provide precise translation and cultural adaptation by native Japanese speakers. Our team ensures that your message resonates with Japanese audiences, considering cultural nuances and preferences.'
        },
        {
          title: 'Design Optimization',
          description: 'We create UI/UX designs aligned with Japanese web design trends. Our designs are tailored to meet the expectations of Japanese users, improving user engagement and conversion rates.'
        },
        {
          title: 'SEO & Performance',
          description: 'We optimize your landing pages for Japanese search engines and implement mobile-first design. This ensures your pages rank well in Japanese search results and provide a smooth experience across all devices.'
        }
      ]
    },
    {
      title: 'Creating Japanese Corporate Websites',
      description: 'We develop customized corporate websites specifically for the Japanese market, effectively representing your company in Japan.',
      imageSrc: '/mockup.png',
      alt: 'Japanese Corporate Website',
      features: [
        {
          title: 'Content Strategy',
          description: 'We develop content strategies adapted to Japanese corporate culture. Our approach ensures your website content aligns with Japanese business practices and customer expectations.'
        },
        {
          title: 'Design & Performance',
          description: 'We implement mobile-first design and optimize performance for the Japanese market. This ensures your website loads quickly and provides a seamless experience across all devices used in Japan.'
        },
        {
          title: 'Compliance & Localization',
          description: 'We ensure compliance with Japanese regulations and provide multilingual content management. This includes creating privacy policies and terms of use that comply with Japanese law, as well as managing both Japanese and English content effectively.'
        }
      ]
    }
  ];

  return (
    <Stack pb={20} width={"100%"} sx={{backgroundColor:"#F9F9F9"}}>
      <Header/>
      <MainImage title={"Our Services"} subTitle={" - Support your challenge - "}/>

      <Stack spacing={isSP ? 6 : 8} mt={isSP ? 3 : 20} pb={0} alignItems={"center"} width={"100%"} sx={{backgroundColor:"#F9F9F9"}}>
        {services.map((service, serviceIndex) => (
          <Stack key={serviceIndex} alignItems={"center"} spacing={4} py={isSP ? 2 : 6} px={isSP ? 0 : 6} width={isSP ? "80%" : "70%"} sx={{backgroundColor:"white", borderRadius:"3px"}}>
            <Typography width={"90%"} textAlign={"center"} sx={{ fontWeight:"bold", fontSize:Math.max(26 * ratio, isSP ? 28: 20)-4, color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{service.title}</Typography> 
            <Stack spacing={isSP ? undefined : 4*ratio} direction={"column"} alignItems={"center"} width="100%">
              <Stack spacing={2} mb={4} width={"90%"}>
                <img src={"/images"+service.imageSrc} style={{ width:"100%", objectFit:"cover", height:undefined, borderRadius:"10px", cursor:"pointer" }} alt={service.alt} />
                <Typography textAlign={isSP ? "flex-start" : undefined} sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{service.description}</Typography> 
              </Stack>

              <Stack width={"90%"} spacing={2}>
                <Typography textAlign={isSP ? "flex-start" : undefined} sx={{ fontWeight:"bold", fontSize:Math.max(20 * ratio, isSP ? 22: 14), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Service Features"}</Typography> 
                {service.features.map((feature, featureIndex) => (
                  <Box 
                    key={featureIndex}
                    onClick={() => toggleFeature(serviceIndex, featureIndex)}
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "40px",
                      marginBottom: "30px",
                      border: "none",
                      transition: "all 0.3s ease",
                      cursor: "pointer",
                      position: "relative",
                      overflow: "hidden",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Box sx={{ padding: "15px 25px" }}>
                      <Typography 
                        component="h4" 
                        sx={{ 
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: Math.max(16 * ratio, isSP ? 15: 12),
                          fontWeight: 600,
                          color: "#1e1e1e",
                          margin: 0,
                          position: "relative",
                          paddingRight: "30px",
                          "&::after": {
                            content: `"${expandedFeatures[`${serviceIndex}-${featureIndex}`] ? '-' : '+'}"`,
                            fontSize: "18px",
                            fontWeight: 500,
                            width: "30px",
                            height: "30px",
                            display: "inline-block",
                            textAlign: "center",
                            lineHeight: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#363636",
                            color: "#fff",
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }
                        }}
                      >
                        {feature.title}
                      </Typography>
                    </Box>
                    <Box 
                      sx={{
                        maxHeight: expandedFeatures[`${serviceIndex}-${featureIndex}`] ? "1000px" : 0,
                        overflow: "hidden",
                        transition: "max-height 0.3s ease, padding 0.3s ease",
                        padding: expandedFeatures[`${serviceIndex}-${featureIndex}`] ? "0 25px 25px 25px" : "0 25px",
                        backgroundColor: "#fff",
                        fontSize: isSP ? Math.max(14 * ratio, 16) : Math.max(16 * ratio, 18),
                        lineHeight: "1.6",
                        color: "#4a4a4a",
                      }}
                    >
                      {feature.description}
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default Service;