import {Box, Divider, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import { useEffect, useRef, useState } from "react";
import { useIsSP } from "../isSP";

const FlexTitle = ({mainTitle, subTitle}) => {

    const stackRef = useRef(null);
    const isSP = useIsSP();
    const [mainFontSize, setMainFontSize] = useState(20);

    useEffect(() => {
        const handleResize = () => {
            if (stackRef.current) {
                const width = stackRef.current.offsetWidth;
                setMainFontSize(Math.min(Math.max(width/ (isSP ? 10 : 20), 24), 36))
            }
        };

        handleResize(); // 初回実行
        window.addEventListener('resize', handleResize); // ウィンドウサイズ変更時にも実行

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, [stackRef.current]);

    return(
        <Stack pb={3} ref={stackRef} width={"100%"} alignItems={"flex-start"}>
            <Typography sx={{fontSize:mainFontSize, color:"#363636", fontWeight:"bold"}}>{mainTitle}</Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Divider sx={{borderWidth: "1px", width:"30px", backgroundColor:"black"}}/>
                <Typography sx={{fontSize:Math.max(mainFontSize - 16, 18), color:"black",}}>{subTitle}</Typography>
            </Stack>
        </Stack>
    )
}

export default FlexTitle