import {Box, Divider, Drawer, IconButton, Stack, Typography, colors} from "@mui/material"
import { useIsSP } from "./isSP"
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
    const isSP = useIsSP()
    const font = {fontSize:isSP ? 14 :  20, color:"white", ":hover":{cursor:"pointer", textDecoration:"underline"}}
    const [anchorSearch, setAnchorSearch] = useState(null);

    return(
        <Stack padding={"10px 20px"} direction={"row"} alignItems={"center"} zIndex={20} height={50} bgcolor={"black"} justifyContent={"space-between"} sx={{position:"fixed", top:0, left:0, right:0}}>
            <img src="/images/logo.svg" style={{width:isSP ? "120px" : "140px", ":hover":{cursor:"pointer"}}} onClick={()=>{window.location.href = "/"}} />
            
            {isSP !== null &&
                isSP !== true ? 
                <Stack direction={"row"} spacing={2}>
                    <Typography sx={font} onClick={()=>{window.location.href = "/service"}}>Services</Typography>
                    <Typography sx={font} onClick={()=>{window.location.href = "/member"}}>About us</Typography>
                    <Typography sx={font} onClick={()=>{window.location.href = "/contact"}}>Contact</Typography>
                </Stack> 
            :
                <MenuIcon onClick={(e)=>{setAnchorSearch(e.currentTarget)}} sx={{ cursor:"pointer", color:"white", fontSize:20}}/>  
            }
                <Drawer
                anchor={"right"}
                open={Boolean(anchorSearch)}
                onClose={()=>{setAnchorSearch(null)}}
                >
                    <Stack alignItems={"center"} width={"240px"}>
                        {/* バツボタン */}
                        <IconButton sx={{position:"absolute", top:12, right:12, height:"44px", width:"44px"}} onClick={()=>{setAnchorSearch(null)}}>
                            <CloseIcon sx={{ color:"grey", fontSize:"32px"}}/> 
                        </IconButton>
                        <Stack spacing={2} alignItems={"center"} pt={10} pb={5} sx={{width:"100%"}} >
                            <Stack spacing={1}  alignItems={"center"} width={"100%"} onClick={()=>{window.location.href = "/"}}>
                                <Typography textAlign={"center"} sx={{...font, color:"grey"}}>TOP</Typography>
                                <Divider width={"90%"}/>
                            </Stack>
                            <Stack spacing={1}  alignItems={"center"} width={"100%"} onClick={()=>{window.location.href = "/service"}}>
                                <Typography textAlign={"center"} sx={{...font, color:"grey"}}>Services</Typography>
                                <Divider width={"90%"}/>
                            </Stack>
                            <Stack spacing={1}  alignItems={"center"} width={"100%"} onClick={()=>{window.location.href = "/member"}}>
                                <Typography textAlign={"center"} sx={{...font, color:"grey"}}>About Us</Typography>
                                <Divider width={"90%"}/>
                            </Stack>
                            <Stack spacing={1} alignItems={"center"} width={"100%"} onClick={()=>{window.location.href = "/contact"}}>
                                <Typography textAlign={"center"} sx={{...font, color:"grey"}}>Contact</Typography>
                                <Divider width={"90%"}/>
                            </Stack>
                        </Stack>

                    </Stack>
                </Drawer>
        </Stack>
    )
}

export default Header