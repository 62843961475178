import {Box, Button, Divider, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import FlexButton from "./Buttons/FlexButton.tsx"
import { useEffect, useRef, useState } from "react";
import FlexTitle from "./Titles/flexTitle.js";
import { useIsSP } from "./isSP.js";

const AboutUS = () => {

    const isIpad = (window.innerWidth >= 450 && window.innerWidth < 1024)
    const stackRef = useRef(null);
    const [mainFontSize, setMainFontSize] = useState(20);
    const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
    const isSP = useIsSP()

    useEffect(() => {
        const handleResize = () => {
            if (stackRef.current) {
                const width = stackRef.current.offsetWidth;
                setMainFontSize(Math.min(Math.max(width/ (isSP ? 18: 25), 16), 24))
            }
            setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
        };

        handleResize(); // 初回実行
        window.addEventListener('resize', handleResize); // ウィンドウサイズ変更時にも実行

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, [stackRef.current]);

    return(
        <Stack ref={stackRef} mt={10} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <FlexTitle mainTitle={"About us"} subTitle={"What we do"} />
            <Stack direction={isSP ? "column" : "row"} justifyContent={"space-between"} width={"100%"} spacing={3} >
                <Stack width={isSP ? "100%" : "50%"} alignItems={"flex-start"} spacing={1}>
                    <Typography sx={{fontSize:mainFontSize, color:"black", fontWeight:"bold"}}>{"Comprehensive support for the overseas company."}</Typography>
                    <Typography sx={{fontSize:Math.max(mainFontSize-4, 14), color:"black",}}>{"Our services include a comprehensive support, all aimed at facilitating sales and market establishment in Japan. "+ (isIpad ? "" : " Our experienced team is dedicated to ensuring your success.")}</Typography>
                </Stack>
                <img src={"/images/img-team.png"} style={{ width:isSP ? "100%" : "50%", height: isSP ? undefined : 300*ratio+"px", objectFit:"cover", borderRadius:"10px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />
            </Stack>
        </Stack>
    )
}

export default AboutUS