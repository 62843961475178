import {Box, Divider, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import FlexTitle from "./Titles/flexTitle";
import { useEffect, useRef, useState } from "react";
import { useIsSP } from "./isSP";
import { motion } from 'framer-motion';

const Difficulties = () => {
    const challengeItems = [
        {
          title: 'Language Barrier',
          description: 'Japanese language proficiency is crucial for business operations, marketing, and customer service. This can be a significant hurdle for foreign companies.',
          imageSrc: '/日本語.png'
        },
        {
          title: 'Unique Business Culture',
          description: 'Japan has a distinct business etiquette and decision-making process. Understanding and adapting to these cultural nuances is essential for success.',
          imageSrc: '/文化.png'
        },
        {
          title: 'Market Entry Strategy',
          description: 'Entering the Japanese market requires thorough research, competitor analysis, and localized marketing strategies. Setting competitive pricing are also crucial for success.',
          imageSrc: '/data.png'
        },
        {
          title: 'Regulatory Complexities',
          description: 'Navigating Japan\'s regulatory environment can be challenging, with specific requirements for various industries and product categories.',
          imageSrc: '/規制.png'
        }
    ];

    const stackRef = useRef(null);
    const [mainFontSize, setMainFontSize] = useState(20);
    const isSP = useIsSP()

    useEffect(() => {
        const handleResize = () => {
            if (stackRef.current) {
                const width = stackRef.current.offsetWidth;
                setMainFontSize(Math.min(Math.max(width/ (isSP ? 18: 25), 16), 24))
            }
        };

        handleResize(); // 初回実行
        window.addEventListener('resize', handleResize); // ウィンドウサイズ変更時にも実行

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, [stackRef.current]);

    const localImageComponent = (imageSrc, title, description) => {
        return (
            <Stack width={"100%"} alignItems={"flex-start"} spacing={2}>
                <img src={"/images"+imageSrc} style={{width:"100%", borderRadius:"10px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />
                <Typography sx={{fontSize:mainFontSize, color:"black", fontWeight:"bold"}}>{title}</Typography>
                <Typography sx={{fontSize:Math.max(mainFontSize-4, 14), color:"#363636",}}>{description}</Typography>
            </Stack>
        )
    }

    const useInView = (ref) => {
        const [inView, setInView] = useState(false);
        useEffect(() => {
            if (!ref.current) {
                return;
            }
            const observer = new IntersectionObserver(([entry]) =>{
                if(entry.isIntersecting === true) setInView(true)
            });
            if(ref.current) observer.observe(ref.current);
        
            return () => {
                observer.disconnect();
            };
        }, [ref.current]);
        
        return inView;
    };
    
    const inView = useInView(stackRef);

    return(
        <Stack ref={stackRef} mt={10} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            
            <FlexTitle mainTitle={"Difficulties"} subTitle={"Key Points for Japan Market Entry"} />

            {isSP ? (
                // スマートフォン版のレイアウト
                <Stack spacing={4}>
                    {challengeItems.map((item, index) => (
                        <motion.div 
                            key={index}
                            style={{width:"100%"}} 
                            initial={{ opacity: 0, y: 10 }} 
                            animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} 
                            transition={{ delay: index * 0.5 + 0.5, duration: 1.0 }}
                        >
                            {localImageComponent(item.imageSrc, item.title, item.description)}
                        </motion.div>
                    ))}
                </Stack>
            ) : (
                // デスクトップ版のレイアウト
                <Stack spacing={6}>
                    <Stack width={"100%"} spacing={4} direction="row" justifyContent={"space-between"}>
                        <motion.div style={{width:"100%"}} initial={{ opacity: 0, y: 10 }} animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} transition={{ delay: 0 + 0.5, duration: 1.0 }}>
                            {localImageComponent(challengeItems[0].imageSrc, challengeItems[0].title, challengeItems[0].description)}
                        </motion.div>
                        
                        <motion.div style={{width:"100%"}} initial={{ opacity: 0, y: 10 }} animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} transition={{ delay: 0.5 + 0.5, duration: 1.0 }}>
                            {localImageComponent(challengeItems[1].imageSrc, challengeItems[1].title, challengeItems[1].description)}
                        </motion.div>
                    </Stack>
                    <Stack width={"100%"} spacing={4} direction="row" justifyContent={"space-between"}>
                        <motion.div style={{width:"100%"}} initial={{ opacity: 0, y: 10 }} animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} transition={{ delay: 1.0 + 0.5, duration: 1.0 }}>
                            {localImageComponent(challengeItems[2].imageSrc, challengeItems[2].title, challengeItems[2].description)}
                        </motion.div>

                        <motion.div style={{width:"100%"}} initial={{ opacity: 0, y: 10 }} animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} transition={{ delay: 1.5 + 0.5, duration: 1.0 }}>
                            {localImageComponent(challengeItems[3].imageSrc, challengeItems[3].title, challengeItems[3].description)}
                        </motion.div>
                    </Stack>
                </Stack>
            )}

            <motion.div 
                style={{
                    width:"100%", 
                    marginTop: isSP ? "48px" : "32px"  // 余白を追加
                }} 
                initial={{ opacity: 0, y: 10 }} 
                animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} 
                transition={{ delay: 2.0 + 0.5, duration: 0.5 }}
            >
                <Typography sx={{fontSize:mainFontSize-3, color:"black",}}>
                    While the Japanese market offers significant opportunities, it also presents unique challenges. Understanding and addressing these key points is crucial for successful market entry and long-term success in Japan.
                </Typography>
            </motion.div>
        </Stack>
    )
}

export default Difficulties