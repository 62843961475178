import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../../pages/home';
import Service from '../../pages/service';
import Member from '../../pages/aboutus';
import Contact from '../../pages/contact';
import Footer from '../footer';
import { CssBaseline } from '@mui/material';


function Router() {
  return (
    <>
    <CssBaseline>
      <Routes>
        <Route path="/" element={<Home />} />         {/* ホームページ */}
        <Route path="/service" element={<Service />} />   {/* Aboutページ */}
        <Route path="/member" element={<Member />} /> {/* Contactページ */}
        <Route path="/contact" element={<Contact />} /> {/* Contactページ */}
      </Routes>
      <Footer/>
      </CssBaseline>
    </>
  );
}

export default Router;