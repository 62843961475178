import {Box, Divider, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import FlexTitle from "./Titles/flexTitle";
import { useEffect, useRef, useState } from "react";
import { useIsSP } from "./isSP";
import { motion } from 'framer-motion';
import { useIsMobile } from "./isMobile";

const JapanMarket = () => {
    const isSP = useIsSP()

    const isIpad = (window.innerWidth >= 450 && window.innerWidth < 1040)
    console.log(isIpad, window.innerWidth)

    const marketItems = [
        {
          title: 'Economic Scale'+(isIpad ? "\n　" : ""),
          description: 'Japan is the world\'s third-largest economy, with a GDP of approximately 4.2 trillion USD in 2022. ' + (!isIpad && 'This vast market size offers significant opportunities for foreign businesses across various sectors.'),
          imageSrc: '/scale_2.png'
        },

        {
          title: 'High Purchasing Power',
          description: 'Japan\'s GDP per capita is around 39,000 USD (as of 2022), indicating strong consumer purchasing power.'+ (!isIpad && 'This high disposable income makes Japan an attractive market for premium products and services.'),
          imageSrc: '/渋谷.png'
        },

        {
          title: 'Overseas Product Brands',
          description: 'Japan is the world\'s 4th largest importer, with 70% of consumers willing to buy foreign brands.' + (!isIpad && ' This openness, coupled with growing e-commerce, offers significant opportunities for overseas companies in various sectors.'),
          imageSrc: '/新商品.png'
        }
      ];

    const useInView = (ref) => {
    const [inView, setInView] = useState(false);
    useEffect(() => {
        if (!ref.current) {
        return;
        }
        const observer = new IntersectionObserver(([entry]) =>{
        if(entry.isIntersecting === true) setInView(true)
        });
        if(ref.current) observer.observe(ref.current);
    
        return () => {
        observer.disconnect();
        };
    }, [ref.current]);
    
    return inView;
    };

    const stackRef = useRef(null);
    const [mainFontSize, setMainFontSize] = useState(20);
    const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
    const stackRef_animate = useRef(null);
    const inView = useInView(stackRef_animate);


    useEffect(() => {
        const handleResize = () => {
            if (stackRef.current) {
                const width = stackRef.current.offsetWidth;
                setMainFontSize(Math.min(Math.max(width/ (isSP ? 18: 25), 10), 24))
            }
            setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
        };

        handleResize(); // 初回実行
        window.addEventListener('resize', handleResize); // ウィンドウサイズ変更時にも実行

        return () => {
            window.removeEventListener('resize', handleResize); // クリーンアップ
        };
    }, [stackRef.current]);

    return(
        <Stack ref={stackRef} mt={isSP ? 2 : 20} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <FlexTitle mainTitle={"Japanese Market"} subTitle={"How Attractive?"} />

            <Stack ref={stackRef_animate} spacing={isSP ? 4 : undefined} direction={isSP ? "column" : "row"} justifyContent={"space-between"}>
                {marketItems.map(((marketItem, index) => ( 
                    <motion.div style={{width:isSP ? "100%" : "32%"}} key={index} initial={{ opacity: 0, y: 10 }} animate={inView ? {opacity: 1, y: 0 } : {opacity: 0, y: 0 }} transition={{ delay: index * 0.5 + 1.0, duration: 1.0 }}>
                        <Stack width={isSP ? "100%" : "100%"} alignItems={"flex-start"} spacing={isSP ? 1 : 1}>
                            <img src={"/images"+marketItem.imageSrc} style={{width:"100%", borderRadius:"3px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />
                            {isSP !== true && <Typography sx={{fontSize:mainFontSize, color:"black", fontWeight:"bold", whiteSpace:"pre-wrap"}}>{marketItem.title}</Typography>}
                            {isSP === true && <Typography sx={{fontSize:mainFontSize, color:"black", fontWeight:"bold", whiteSpace:"pre-wrap"}}>{marketItem.title}</Typography>}
                            <Typography sx={{fontSize:Math.max(mainFontSize-4, 12), color:"black", whiteSpace:"pre-wrap"}}>{marketItem.description}</Typography>
                        </Stack>
                    </motion.div>
                )))}
            </Stack>
            <Stack ></Stack>
            
        </Stack>
    )
}

export default JapanMarket