import logo from './logo.svg';
import Header from './components/header';
import MainImage from './components/main_image';
import {Box, Stack, Typography, colors} from "@mui/material"
import JapanMarket from './components/japanMarket';
import Difficulties from './components/Difficulties';
import Services from './components/Services';
import AboutUS from './components/AboutUS';
import Contact from './components/Contact';
import React, { useState } from 'react';
import { useIsSP } from './components/isSP';

function App() {

  const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
  const isSP = useIsSP()

   React.useEffect(() => {
    const handleResize = () => {
      setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const temp = {
    content: '""',
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100px",
    backgroundColor: "#F9F9F9",
  }

  return (
    <Stack pb={20} width={"100%"} sx={{backgroundColor:"#F9F9F9"}}>
      <Header/>
      <MainImage/>

      <Stack pb={0} alignItems={"center"} width={"100%"} sx={{backgroundColor:"#F9F9F9",
        "&::after": {...temp, backgroundColor: "white", clipPath: "polygon(0 100%, 100% 0, 100% 100%)"},
      }}>
        <Stack width={100 - 20 * ratio+"%"}>
          <JapanMarket/>
        </Stack>
      </Stack>

      <Stack pb={10} alignItems={"center"} width={"100%"} sx={{backgroundColor:"white",
      }} >
        <Stack width={100 - 20 * ratio+"%"}>
          <Difficulties/>
        </Stack>
      </Stack>

      <Stack  alignItems={"center"} width={"100%"} sx={{backgroundColor:"#F9F9F9",
        "&::before": {...temp, height:isSP ? 100 : 100, backgroundColor:"white", clipPath: "polygon(0 0, 50% 100%, 100% 0)"},
        "&::after": {...temp, backgroundColor: "white", clipPath: "polygon(0 100%, 100% 0, 100% 100%)"},
      }}  >
        <Stack width={100 - 20 * ratio+"%"}>
          <Services/>
        </Stack>
      </Stack>

      <Stack alignItems={"center"} width={"100%"} sx={{backgroundColor:"white",
        "&::after": {...temp,clipPath: "polygon(0 100%, 100% 0, 100% 100%)"},
      }}  >
        <Stack width={100 - 20 * ratio+"%"}>
          <AboutUS/>
        </Stack>
      </Stack>

      <Stack alignItems={"center"} width={"100%"} sx={{backgroundColor:"#F9F9F9"}}  >
        <Stack width={100 - 20 * ratio+"%"}>
          <Contact/>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default App;
