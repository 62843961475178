import {Box, Button, Divider, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import FlexButton from "./Buttons/FlexButton.tsx"
import FlexTitle from "./Titles/flexTitle.js";
import React, { useEffect, useRef, useState } from "react";
import { useIsSP } from "./isSP.js";
import { motion } from 'framer-motion';
import { useIsMobile } from "./isMobile";

const Services = () => {


  const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
  const isSP = useIsSP()

    const services = [
        {
          title: 'Consulting: Product Selection for the Japanese Market',
          description: 'We assist in identifying products that best suit the Japanese market, ensuring a higher chance of success upon entry.',
          imageSrc: '/consulting1.png',
          alt: 'Product Selection',
          width: isSP ? "100%" : "40%",
          height: 300*ratio+"px",
        }, 
        { 
          title: 'Test Marketing in Real Japanese Market: Comprehensive Crowdfunding Support',
          description: 'We provide extensive support for launching crowdfunding campaigns, enabling real-time market feedback and product validation.',
          imageSrc: '/クラファン.png',
          alt: 'Crowdfunding Support',
          width: isSP ? "100%" : "40%",
          height: 300*ratio+"px",
        },
        {
          title: 'Creating Japanese Landing Page: Product-Specific Promotion',
          description: 'We create specialized Japanese landing pages for each product, effectively promoting your offerings to the Japanese audience.',
          imageSrc: '/LP集.png',
          alt: 'Japanese Landing Page',
          width: isSP ? "100%" : "40%",
          height: 300*ratio+"px",
        },
        {
          title: 'Creating Japanese Corporate Website: Targeted for the Japanese market',
          description: 'We develop customized Japanese corporate websites, tailored to represent your company effectively in the Japanese market.',
          imageSrc: '/mockup.png',
          alt: 'Japanese Corporate Website',
          width: isSP ? "100%" : "40%",
          height: 300*ratio+"px",
        }
      ];

      const useInView = (ref) => {
        const [inView, setInView] = useState(false);
        useEffect(() => {
            if (!ref.current) {
            return;
            }
            const observer = new IntersectionObserver(([entry]) =>{
            if(entry.isIntersecting === true) setInView(true)
            });
            if(ref.current) observer.observe(ref.current);
        
            return () => {
            observer.disconnect();
            };
        }, [ref.current]);
        
        return inView;
        };

    const stackRef = useRef(null);
    const [mainFontSize, setMainFontSize] = useState(20);
    const stackRef_animate = useRef(null);
    const inView = useInView(stackRef);

    React.useEffect(() => {
       const handleResize = () => {
        if (stackRef.current) {
          const width = stackRef.current.offsetWidth;
          setMainFontSize(Math.min(Math.max(width/ (isSP ? 18: 25), 16), 24))
        }
         setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 0.8)))
       };
       window.addEventListener('resize', handleResize);
       return () => {
         window.removeEventListener('resize', handleResize);
       };
     }, []);
    

    return(
        <Stack ref={stackRef} alignItems={"center"} justifyContent={"center"} width={"100%"} spacing={3}>
            <FlexTitle mainTitle={"Service"} subTitle={"We will support these difficulties"} />
            {services.map((service, index) => (
                <motion.div style={{width:"100%"}} key={index} initial={{ opacity: 0, x: 10 * (index % 2 ? -1 : 1) }} animate={inView ? {opacity: 1, x: 0 } : {opacity: 0, x: 0 }} transition={{ delay: index * 0.5 + 0.7, duration: 1.5 }}>
                  <Stack p={4} spacing={2} direction={isSP ? "column" : "row"} justifyContent={"space-between"} sx={{ borderRadius:"5px", boxShadow:'0 4px 4px 0 rgba(0, 0, 0, 0.2)', border:"0.5px solid grey"}} >
                      {(isSP !== true && index % 2 === 1) && <img src={"/images"+service.imageSrc} style={{ width:service.width, objectFit:"cover", height:service.height, borderRadius:"10px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />}
                      {isSP  && <img src={"/images"+service.imageSrc} style={{ width:service.width, objectFit:"cover", height:isSP ? undefined :service.height, borderRadius:"10px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />}
                      <Stack justifyContent={"center"} alignItems={"flex-start"} spacing={1}>
                          <Typography sx={{lineHeight:isSP ? 1.5 : Math.max(1.8 * ratio, 1.1), fontSize:isSP ? mainFontSize-1 : mainFontSize, color:"#363636", fontWeight:"bold"}}>{service.title}</Typography>
                          <Typography sx={{lineHeight:isSP ? 1.5 : Math.max(1.8 * ratio, 1.1), fontSize: isSP ? mainFontSize-2 : Math.max(mainFontSize-1, 10), color:"#363636",}}>{service.description}</Typography>
                      </Stack>
                      {(isSP !== true && index % 2 === 0) && <img src={"/images"+service.imageSrc} style={{ width:service.width, objectFit:"cover", height:service.height, borderRadius:"10px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />}
                  </Stack>
                </motion.div>
            ))}

            <Stack pt={4} width={"100%"} alignItems={"flex-start"}>
              <FlexButton borderRadius={"3px"} fontWeight={"bold"}  text="More" width={isSP ? "100%" : "30%"} height={isSP ? "60px" : "60px"} fontSize={isSP ? 18 : 15} backgroundColor="#363636" onClick={()=>{window.location.href = "/service"}}/>
            </Stack>
            
        </Stack>
    )
}

export default Services