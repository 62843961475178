import {Box, Stack, Typography, colors} from "@mui/material"
import { useIsSP } from "./isSP"

const Footer = () => {
    const isSP = useIsSP()
    const font = {fontSize:isSP ? 14 :  16, color:"white", ":hover":{cursor:"pointer", textDecoration:"underline"}}

    return(
        <Stack spacing={2} padding={"10px 20px"} direction={"column"} alignItems={"center"} bgcolor={"black"} justifyContent={"space-between"}  sx={{left:0, right:0}}>
            <Stack direction={"row"} spacing={2}>
                <Typography sx={font} onClick={()=>{window.location.href = "/service"}}>Service</Typography>
                <Typography sx={font} onClick={()=>{window.location.href = "/member"}}>Member</Typography>
                <Typography sx={font} onClick={()=>{window.location.href = "/contact"}}>Contact</Typography>
            </Stack>
            <Typography sx={font} onClick={()=>{window.location.href = "/contact"}}>©︎ RE-IDEA Co.,Ltd. All Rights Reserved 2024</Typography>
        </Stack>
    )
}

export default Footer;