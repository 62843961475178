import React, { useState, useEffect, useContext, useRef } from 'react';
import {Box, Stack, Typography, colors} from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import { useIsSP } from './isSP';

const MainImage = ({title=undefined, subTitle=undefined, displayContact=true}) => {

    const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
    const isSP = useIsSP()

   React.useEffect(() => {
    const handleResize = () => {
      setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return(
        <Stack width={"100%"}>
            <Stack top={45} sx={{minHeight:isSP ? 200 : 400, height:isSP ? 300 : 550*(ratio-0.2)+"px", maxHeight:"500px", }} justifyContent={"center"} width={"100%"} position={"relative"}>
                <img src="/images/tower.png" style={{objectPosition: (isSP ? 80 : 90) +"% 0%", width:"100%", height:"100%",  ":hover":{cursor:"pointer"}, position:"absolute", filter: "brightness(60%)", objectFit:"cover", aspectRatio:"10/3"}} onClick={()=>{window.location.href = "/contact"}} />
                <Stack spacing={2} alignItems={"center"} justifyContent={"center"} width={"100%"} maxHeight={500} minHeight={350} height={500*(ratio-0.2)+"px"} position={"relative"}>
                    <Typography textAlign={isSP ? "center" : undefined } sx={{fontSize:Math.max(30 * ratio, isSP ? 28: 24), color:"white", zIndex:1, fontWeight:"bold", whiteSpace:"pre-wrap"}}>{title ?? "All-In-One Support for Japan Market Entry"}</Typography>
                    <Typography textAlign={isSP ? "center" : undefined }  sx={{fontSize:Math.max(16 * ratio, isSP ? 16 : 14), color:"white", zIndex:1, whiteSpace:"pre-wrap"}}>{subTitle ?? "Test marketing in the Japanese market and"+(isSP ? "\n" : "")+" creating a Japanese website"}</Typography>
                </Stack>
            </Stack>
            
            {isSP !== true ? 
                displayContact &&
                    <>
                        <Stack alignItems={"center"} justifyContent={"center"} sx={{position:"relative", }}>
                            <Stack padding={20*ratio+"px "+40*ratio+"px"} alignItems={"flex-start"} justifyContent={"center"} sx={{position:"absolute", zIndex:1, top:30-(15*ratio), width:isSP ? "100%" : "50%", height:Math.min(90*ratio, 85)+"px", left: '50%',transform: 'translateX(-50%)', backgroundColor:"white", borderRadius:"3px"}}>
                                <Typography sx={{fontSize:Math.max(12, Math.min(20*ratio, 24)), color:"black", fontWeight:"bold"}}>Contact</Typography>
                                <Typography sx={{fontSize:Math.max(12*ratio, 8), color:"black"}}>Ask for Free Consultation</Typography>
                            </Stack>
                        </Stack>
                        <Stack onClick={()=>{window.location.href = "/contact"}} sx={{position:"relative", ":hover": {cursor:"pointer"} }}>
                            <Stack p={Math.min(2*ratio, 2)} direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{position:"absolute", zIndex:1, left: '80%', borderRadius:"3px", transform: 'translateX(-50%)', top:Math.min(80*ratio, 80), width:200*ratio+"px", height:Math.min(70*ratio, 50)+"px", ":hover":{cursor:"pointer"}, backgroundColor:"#363636"}}>
                                <Typography sx={{fontSize: Math.max(12, Math.min(16*ratio, 18)), color:"white"}}>Move</Typography>
                                <Typography sx={{fontSize: Math.max(12, Math.min(16*ratio, 18)), color:"white"}}>→</Typography>
                            </Stack>
                        </Stack>
    
                    </>
            :

            <>
                {displayContact && 
                    <Stack width={"100%"} alignItems={"center"} justifyContent={"center"} sx={{position:"relative",  }}>
                        <Stack p={2} spacing={4} width={"80%"}  sx={{backgroundColor: "white"}}>
                            <Stack padding={"20px 20px" }  width={"100%"} alignItems={"flex-start"} backgroundColor={"white"} justifyContent={"center"} sx={{zIndex:1,width:"100%", height:Math.min(60*ratio, 55)+"px", backgroundColor:"white", borderRadius:"3px"}}>
                                <Typography sx={{fontSize:Math.max(20, Math.min(24*ratio, 24)), color:"black", fontWeight:"bold"}}>Contact</Typography>
                                <Typography sx={{fontSize:Math.max(22*ratio, 14), color:"black"}}>Ask for Free Consultation</Typography>
                            </Stack>

                            <Stack onClick={()=>{window.location.href = "/contact"}} width={"100%"} alignItems={"center"} sx={{}}>
                                <Stack padding={"25px 20px"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{zIndex:1, borderRadius:"3px", width:isSP ? "80%" : 200*ratio+"px", height:Math.min(50*ratio, 40)+"px", ":hover":{cursor:"pointer"}, backgroundColor:"#363636"}}>
                                    <Typography sx={{ fontWeight:"bold",  marginLeft:"20px", fontSize: Math.max(18, Math.min(18*ratio, 20)), color:"white"}}>Move</Typography>
                                    <Typography sx={{ fontWeight:"bold", marginRight:"20px",fontSize: Math.max(18, Math.min(18*ratio, 20)), color:"white"}}>→</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        
                    </Stack>
                }
            </>
            }

        </Stack>
    )
}

export default MainImage
