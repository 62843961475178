import Header from '../components/header.js';
import MainImage from '../components/main_image.js';
import {Box, Divider, Stack, Typography, colors} from "@mui/material"
import JapanMarket from '../components/japanMarket.js';
import Difficulties from '../components/Difficulties.js';
import Services from '../components/Services.js';
import AboutUS from '../components/AboutUS.js';
import Contact from '../components/Contact.js';
import React, { useState } from 'react';
import { useIsSP } from '../components/isSP.js';
import LimitedMultilineOutlinedBordered from '../components/Textfields/limited_multiline_outlined_bordered.js';
import FlexButton from '../components/Buttons/FlexButton.tsx';

function AboutUs() {

  const [ratio, setRatio] = useState(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
  const isSP = useIsSP()
  const profiles = [
    {
      name: "Ryosuke Nakai",
      role: "Chief Executive Officer (CEO)",
      description: "Involved in supporting overseas companies in their endeavors to challenge the Japanese domestic crowdfunding market. Leveraging my experiences, I am passionate about assisting clients with their Japan Market Entry.",
      imageSrc: "IMG_5132.jpg",
    },
    {
      name: "Genki Hosomi",
      role: "Chief Technology Officer (CTO)",
      description: "Involved in supporting overseas companies in their endeavors to challenge Japan Market Entry through creating Japanese targeted landing page and coporate websites.",
      imageSrc: "IMG_5113.jpg",
    },
    {
      name: "Ryosuke Kubo",
      role: "Chief Marketing Officer (CMO)",
      description: "Involved in supporting overseas companies in their endeavors to challenge the Japanese domestic crowdfunding market. Leveraging my experiences, I am passionate about assisting clients with their Japan Market Entry.",
      imageSrc: "IMG_5123.jpg",
    },
    {
      name: "Ayako Nunobe",
      role: "Chief Engineer",
      description: "Involved in supporting overseas companies in their endeavors to challenge the Japan Market Entry through creating Japanese targeted websites.",
      imageSrc: "IMG_5098.jpg",
    }
  ];
  

   React.useEffect(() => {
    const handleResize = () => {
      setRatio(Math.max(0.50, Math.min(window.innerWidth / 1000, 1.5)))
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Stack pb={20} width={"100%"} sx={{backgroundColor:"#F9F9F9"}}>
      <Header/>
      <MainImage title={"About us"} subTitle={"- Company / Members -"} displayContact={false} />

      {/* Company */}
      <Stack pb={0} alignItems={"center"} width={"100%"} >
        <Stack alignItems={"center"} spacing={1} p={4} width={isSP ? "80%" : 100 - 30 * ratio+"%"} sx={{zIndex:10, borderRadius:"3px",  backgroundColor:"white"}}>
          <Stack p={4}>
            <Typography textAlign={"center"}  sx={{ fontWeight:"bold", fontSize:Math.max(22 * ratio, isSP ? 24: 18), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Company"}</Typography> 
          </Stack>

          <Stack width={"100%"} px={1} py={5} spacing={0} alignItems={"center"} sx={{backgroundColor:"#F9F9F9"}}>
            <Typography textAlign={"center"}  sx={{ paddingBottom:"15px", fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Empowering Global Businesses in the Japanese Market"}</Typography> 
            <Stack width={"100%"} p={1} alignItems={"center"} spacing={1} >
              <Stack width={"100%"} direction={isSP ? "column" : "row"} spacing={isSP ? 1 : undefined} alignItems={isSP ? undefined : "center"} justifyContent={isSP ? undefined : "space-between"} >
                <Typography width={isSP ? "100%" : "40%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontWeight:"bold", fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Company Name"}</Typography> 
                <Typography width={isSP ? "100%" : "60%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"RE-IDEA Co., Ltd."}</Typography> 
              </Stack>
              <Divider sx={{width:"100%"}}/>
            </Stack>
            <Stack width={"100%"} p={1} alignItems={"center"} spacing={1} >
              <Stack width={"100%"} direction={isSP ? "column" : "row"} spacing={isSP ? 1 : undefined} alignItems={isSP ? undefined : "center"} justifyContent={isSP ? undefined : "space-between"} >
                <Typography width={isSP ? "100%" : "40%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontWeight:"bold", fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Establishment"}</Typography> 
                <Typography width={isSP ? "100%" : "60%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"May 2024"}</Typography> 
              </Stack>
              <Divider sx={{width:"100%"}}/>
            </Stack>
            <Stack width={"100%"} p={1} alignItems={"center"} spacing={1} >
              <Stack width={"100%"} direction={isSP ? "column" : "row"} spacing={isSP ? 1 : undefined} alignItems={isSP ? undefined : "center"} justifyContent={isSP ? undefined : "space-between"} >
                <Typography width={isSP ? "100%" : "40%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontWeight:"bold", fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Post Code"}</Typography> 
                <Typography width={isSP ? "100%" : "60%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"〒151-0051\n1-30-10-4F"+(isSP ? "\n" : " ")+"Sendagaya,Shibuya-ku,Tokyo"}</Typography> 
              </Stack>
              <Divider sx={{width:"100%"}}/>
            </Stack>
            <Stack width={"100%"} p={1} alignItems={"center"} spacing={1} >
              <Stack width={"100%"} direction={isSP ? "column" : "row"} spacing={isSP ? 1 : undefined} alignItems={isSP ? undefined : "center"} justifyContent={isSP ? undefined : "space-between"} >
                <Typography width={isSP ? "100%" : "40%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontWeight:"bold", fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Representative"}</Typography> 
                <Typography width={isSP ? "100%" : "60%"} textAlign={isSP ? "center" : "flex-start"} sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Ryosuke Nakai"}</Typography> 
              </Stack>
              <Divider sx={{width:"100%"}}/>
            </Stack>
          </Stack>

          {/* Members */}
          <Stack p={4}>
            <Typography textAlign={"center"}  sx={{ fontWeight:"bold", fontSize:Math.max(22 * ratio, isSP ? 24: 18), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{"Members"}</Typography> 
          </Stack>
          <Stack spacing={6} alignItems={"center"}>
            {profiles.map((profile, index) => (
              <Stack direction={isSP ? "column" : index % 2 === 0 ? "row" : "row-reverse"} spacing={isSP ? undefined : 6} width={"100%"} alignItems={"center"}>
                <img src={"/images/"+profile.imageSrc} style={{ width:isSP ? "60%" : "40%", objectFit:"cover", height:undefined, borderRadius:"3px", ":hover":{cursor:"pointer"}, }} onClick={()=>{}} />
                <Stack p={isSP ? 1 : 1}>
                  <Typography textAlign={"flex-start"} pt={2} sx={{ fontWeight:"bold", fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{profile.name}</Typography> 
                  <Typography textAlign={"flex-start"} pt={2}  sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{profile.role}</Typography> 
                  <Typography textAlign={"flex-start"} pt={2}  sx={{ fontSize:Math.max(14 * ratio, isSP ? 16: 10), color:"#363636", zIndex:1, whiteSpace:"pre-wrap"}}>{profile.description}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>


    </Stack>
  );
}

export default AboutUs;
